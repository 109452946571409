import { render, staticRenderFns } from "./InboundTransfersDataTable.template.vue?vue&type=template&id=e5ca5e78&scoped=true&"
var script = {}
import style0 from "./InboundTransfersDataTable.template.vue?vue&type=style&index=0&id=e5ca5e78&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5ca5e78",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./QuotesDataTable.template.vue?vue&type=template&id=27e0cb66&scoped=true&"
var script = {}
import style0 from "./QuotesDataTable.template.vue?vue&type=style&index=0&id=27e0cb66&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27e0cb66",
  null
  
)

export default component.exports